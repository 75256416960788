import { Button, CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getPackageByCounseller, postcreatePayment } from "../../Services/api";
import QRCodeImage from "./qr.jpeg";

const CounsellerPackageScreen = () => {
  const navigate = useNavigate();
  const [packages, setPackages] = useState([]);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [loading, setLoading] = useState(true);
  const [screenshot, setScreenshot] = useState(null);
  const [error, setError] = useState("");

  // Fetch packages for counselor
  useEffect(() => {
    const fetchPackages = async () => {
      try {
        const response = await getPackageByCounseller();
        setPackages(response.data);
      } catch (error) {
        console.error("Error fetching packages for counsellors:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchPackages();
  }, []);

  // Handle package selection
  const handlePackageSelection = (pkg) => {
    setSelectedPackage(pkg);
    setError("");
  };

  // Handle screenshot upload
  const handleScreenshotUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setScreenshot(file);
      setError("");
    }
  };

  // Handle payment process
  const continueToPayment = async (e) => {
    e.preventDefault();

    if (!selectedPackage) {
      setError("Please select a package.");
      return;
    }

    if (!screenshot) {
      setError("Please upload a payment screenshot.");
      return;
    }

    const userId = localStorage.getItem("userId");
    if (!userId) {
      setError("User not found. Please log in again.");
      return;
    }

    const formData = new FormData();
    formData.append("userId", userId);
    formData.append("packageId", selectedPackage._id);
    formData.append("screenshot", screenshot);

    try {
      const response = await postcreatePayment(formData);
      console.log("Payment created successfully", response.data);
      navigate("/dashboard");
    } catch (error) {
      console.error("Error creating payment:", error);
      setError("Failed to create payment. Please try again.");
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        backgroundColor: "#f0f2f5",
        padding: "2rem",
      }}
    >
      <form
        onSubmit={continueToPayment}
        style={{
          maxWidth: "600px",
          width: "100%",
          backgroundColor: "#ffffff",
          borderRadius: "12px",
          boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
          padding: "2rem",
          textAlign: "center",
        }}
      >
        <h2 style={{ marginBottom: "1.5rem", color: "#333", fontWeight: "600" }}>
          Counsellor Package
        </h2>

        {loading ? (
          <CircularProgress />
        ) : packages.length > 0 ? (
          <div>
            {packages.map((pkg) => (
              <div
                key={pkg._id}
                style={{
                  marginBottom: "1.5rem",
                  padding: "1.5rem",
                  border: "1px solid #e0e0e0",
                  borderRadius: "8px",
                  backgroundColor: selectedPackage === pkg ? "#e6f7ff" : "#f9f9f9",
                  cursor: "pointer",
                  transition: "transform 0.2s ease, box-shadow 0.2s ease",
                }}
                onClick={() => handlePackageSelection(pkg)}
              >
                <h3
                  style={{
                    fontSize: "1.25rem",
                    fontWeight: "600",
                    color: "#333",
                    marginBottom: "0.5rem",
                  }}
                >
                  {pkg.packageName || "Job Seeker Package"}
                </h3>
                <p style={{ margin: "0.5rem 0", color: "#555" }}>
                  Duration: {pkg.months} months
                </p>
                <p style={{ margin: "0.5rem 0", color: "#777" }}>
                  Actual Price: {pkg.prize}
                </p>
                <p
                  style={{
                    margin: "0.5rem 0",
                    fontWeight: "700",
                    color: "#007bff",
                  }}
                >
                  Special Price: {pkg.specialPrize}
                </p>
              </div>
            ))}
          </div>
        ) : (
          <p>No packages available.</p>
        )}

        {selectedPackage && (
          <div style={{ marginTop: "1.5rem", textAlign: "center" }}>
            <p style={{ marginBottom: "1rem", color: "#333" }}>
              Scan the QR code below to pay:
            </p>
            <img
              src={QRCodeImage}
              alt="QR Code"
              style={{
                width: "250px",
                height: "250px",
                marginBottom: "1rem",
                borderRadius: "8px",
              }}
            />
            <input
              type="file"
              accept="image/*"
              onChange={handleScreenshotUpload}
              style={{
                display: "block",
                margin: "1rem auto",
              }}
            />
          </div>
        )}

        {error && <p style={{ color: "red", marginTop: "1rem" }}>{error}</p>}

        <Button
          type="submit"
          variant="contained"
          size="large"
          style={{
            marginTop: "2rem",
            width: "100%",
            backgroundColor: "#007bff",
            color: "#fff",
            padding: "0.75rem",
            borderRadius: "6px",
            fontWeight: "600",
          }}
          onMouseEnter={(e) => (e.currentTarget.style.opacity = "0.9")}
          onMouseLeave={(e) => (e.currentTarget.style.opacity = "1")}
        >
          Continue
        </Button>
      </form>
    </div>
  );
};

export default CounsellerPackageScreen;
